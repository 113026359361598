import React, { useEffect, useMemo, useState } from "react";
import { GOOGLE_MAPS_API_KEY } from "~/config/env";
import { styled } from "~/styles/stitches.config";
import { DoctorFragment, OfficeAddressFragment } from "~/types/graphql";
import GoogleMapReact, { Coords } from "google-map-react";
import { DoctorMapPin } from "~/components";

export interface DoctorsMapProps {
  doctors: DoctorFragment[];
  opened: boolean;
  selectedPin?: string;
  setSelectedPin: (value?: string) => void;
  hoveredDoctor?: string;
}

const Container = styled("div", {
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  pointerEvents: "none",
  transition: "opacity 0.25s ease-out",
  opacity: 0,
  zIndex: 3,
  display: "none",
  variants: {
    opened: {
      true: {
        pointerEvents: "all",
        transition: "opacity 0.25s ease-in",
        opacity: 1,
        display: "block",
      },
    },
  },
  "@bp1": {
    display: "block",
    pointerEvents: "all",
    zIndex: 0,
    position: "relative",
    width: "50%",
    height: "auto",
    opacity: 1,
    py: "$4",
    paddingLeft: 40,
  },
});

const Map = styled("div", {
  width: "100%",
  height: "100%",
  position: "relative",
  "@bp1": {
    borderRadius: "16px 0px 0px 16px",
    overflow: "hidden",
    width: "calc(50vw - 40px)",
  },
});

interface Location {
  address: OfficeAddressFragment;
  doctor: DoctorFragment;
}

export const DoctorsMap = ({
  doctors,
  opened,
  selectedPin,
  setSelectedPin,
  hoveredDoctor,
}: DoctorsMapProps) => {
  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();

  const locations = useMemo(() => {
    const list: Location[] = [];
    doctors.forEach(doctor => {
      if (
        doctor.officeAddress?.coordinates &&
        !doctor.officeAddress.temporaryVirtualOffice
      ) {
        list.push({ address: doctor.officeAddress, doctor });
      }
      if (
        doctor.secondaryOfficeAddress?.coordinates &&
        !doctor.secondaryOfficeAddress.temporaryVirtualOffice
      ) {
        list.push({ address: doctor.secondaryOfficeAddress, doctor });
      }
    });
    return list;
  }, [doctors]);

  useEffect(() => {
    if (locations.length > 0 && !!maps && !!map) {
      const latlngbounds = new maps.LatLngBounds();
      locations.forEach(location => {
        latlngbounds.extend(
          new maps.LatLng(
            location.address.coordinates![0],
            location.address.coordinates![1],
          ),
        );
      });
      map.fitBounds(latlngbounds);
    }
  }, [locations, maps, map]);

  const selectPin = (id: string, coords: Coords) => {
    setSelectedPin(id);
    map.setCenter(new maps.LatLng(coords.lat, coords.lng));
  };

  const onGoogleApiLoaded = ({
    maps,
    map,
  }: {
    map: any;
    maps: any;
    ref: Element | null;
  }) => {
    setMap(map);
    setMaps(maps);
  };

  return (
    <Container opened={opened}>
      <Map>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          draggable={true}
          options={{
            draggable: true,
            scrollwheel: true,
            fullscreenControl: false,
            backgroundColor: "#fffff",
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{
            lat: 19.4200996,
            lng: -99.1624399,
          }}
          defaultZoom={15}
          onGoogleApiLoaded={onGoogleApiLoaded}
          onDrag={() => {
            setSelectedPin(undefined);
          }}
        >
          {locations.map(({ address, doctor }) => (
            <DoctorMapPin
              key={`${address.id}-${doctor.uuid}`}
              lat={address.coordinates![0]!}
              lng={address.coordinates![1]!}
              doctor={doctor}
              showBalloon={selectedPin === address.id}
              hovered={doctor.uuid === hoveredDoctor}
              onClick={() =>
                selectPin(address.id!, {
                  lat: address.coordinates![0]!,
                  lng: address.coordinates![1]!,
                })
              }
            />
          ))}
        </GoogleMapReact>
      </Map>
    </Container>
  );
};
