import {
  FooterLinkBlock,
  FooterLinkBlockProps,
  Grid,
  PaddedScreenContainer,
} from "~/components";
import { styled } from "~/styles/stitches.config";

const LINKS: FooterLinkBlockProps[] = [
  {
    title: "Contáctanos",
    links: [
      {
        text: "hola@sofiasalud.com",
        href: "mailto:hola@sofiasalud.com",
      },
    ],
  },
  {
    title: "Más sobre Sofía",
    links: [
      {
        text: "Preguntas Frecuentes",
        href: "https://www.sofiasalud.com/otros/preguntas-frecuentes",
      },
      {
        text: "Trabaja con nosotros",
        href: "https://www.sofiasalud.com/unete-equipomedico",
      },
      {
        text: "Blog",
        href: "https://www.sofiasalud.com/blog",
      },
      {
        text: "Guías de marca",
        href: "https://brand.sofiasalud.com/?_ga=2.131525775.1602816182.1688512600-1692638019.1688512600",
      },
      {
        text: "Opiniones",
        href: "https://www.sofiasalud.com/opiniones",
      },
      {
        text: "Seguro médico para trabajadores",
        href: "https://www.sofiasalud.com/seguro-medico-para-trabajadores",
      },
      {
        text: "Te ayudamos a cumplir con la NOM-035",
        href: "https://www.sofiasalud.com/nom-035",
      },
    ],
  },
  {
    title: "Legales",
    links: [
      {
        text: "Aviso de Privacidad",
        href: "https://www.sofiasalud.com/otros/aviso-de-privacidad",
      },
      {
        text: "UNE Sofía",
        href: "https://www.sofiasalud.com/otros/une",
      },
      {
        text: "Términos y Condiciones",
        href: "https://www.sofiasalud.com/otros/terminos-y-condiciones",
      },
      {
        text: "Estados financieros",
        href: "https://www.sofiasalud.com/otros/estados-financieros",
      },
      {
        text: "Guías de bienvenida",
        href: "https://www.sofiasalud.com/otros/guia-de-bienvenida",
      },
      {
        text: "Condiciones generales",
        href: "https://www.sofiasalud.com/otros/condiciones-generales",
      },
      {
        text: "Directorio SofíaMed",
        href: "https://www.sofiasalud.com/otros/directorios",
      },
      {
        text: "Tabuladores médicos",
        href: "https://sofia-public-assets.s3.us-east-2.amazonaws.com/website/PDF/TabuladoresSofi%CC%81a+-TabuladoresSofi%CC%81a+vistadoctor%40s.pdf",
      },
      {
        text: "Guías médicas",
        href: "https://www.sofiasalud.com/otros/guias-medicas",
      },
    ],
  },
];

const SOCIAL_LINKS = [
  {
    icon: (
      <svg
        fill="none"
        height="17"
        viewBox="0 0 16 17"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="#4a25aa">
          <path
            clipRule="evenodd"
            d="m14.6389 3.67267c.1724.42595.2927.91751.3255 1.63251.0328.71768.0356.94636.0356 2.77336s-.0082 2.05566-.041 2.77336c-.0328.715-.1533 1.204-.3255 1.6325-.3638.9043-1.1079 1.6193-2.0489 1.9689-.4431.1657-.9546.2813-1.6987.3128-.7468.0316-.98472.0394-2.88585.0394s-2.1392-.0078-2.88589-.0394c-.74412-.0315-1.25285-.1471-1.69875-.3128-.46502-.1682-.88901-.4337-1.23917-.7755-.35561-.3365-.63182-.7413-.80963-1.1934-.17235-.4259-.29277-.9175-.32558-1.6325-.0328-.7177-.04103-.94636-.04103-2.77336s.00823-2.05568.04381-2.77603c.0328-.715.15312-1.20389.32547-1.6324.17514-.4469.45135-.85436.80696-1.19086.35016-.34441.77137-.60729 1.24195-.77816.44312-.16553.95463-.28126 1.69865-.31278.74679-.03153.98475-.03943 2.88588-.03943s2.13908.0079 2.88868.0421c.744.03152 1.2528.14715 1.6986.31278.4651.1682.8891.43375 1.2392.77549.3584.3365.6319.74129.8097 1.19342zm-1.176 8.37543c.0957-.2366.2106-.5915.2407-1.2487.0328-.7097.0411-.92002.0411-2.71547s-.0083-2.00831-.0411-2.71807c-.0301-.65462-.145-1.01207-.2407-1.24866-.1122-.29183-.29-.5547-.5225-.77291-.2243-.22344-.5006-.39431-.8042-.50202-.2462-.09201-.6155-.2024-1.2993-.23135-.7359-.03153-.95742-.03944-2.82852-.03944-1.86821 0-2.08982.00791-2.82839.03944-.68108.02895-1.05314.13934-1.29932.23135-.30368.10771-.57722.27858-.80418.50202-.23529.21564-.41309.48108-.52529.77291-.09574.23659-.2106.59147-.24063 1.24866-.03291.70709-.04103.92262-.04103 2.71807s.00812 2.00847.04103 2.71817c.03003.6545.14489 1.0121.24063 1.2487.1122.2917.29.5546.52251.7728.22429.2234.50061.3943.80418.5021.24619.092.61547.2024 1.29933.2312.73589.0317.96017.0395 2.82849.0395s2.08979-.0078 2.82839-.0395c.6812-.0288 1.0531-.1392 1.2993-.2312.6101-.2261 1.0942-.6914 1.3295-1.2776z"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="m4.40576 8.0784c0-1.90852 1.61123-3.45682 3.59709-3.45682 1.98597 0 3.59705 1.5483 3.59705 3.45682s-1.61108 3.4568-3.59705 3.4568c-1.98586 0-3.59709-1.54828-3.59709-3.4568zm1.26391-.00012c0 1.23816 1.04502 2.24232 2.33335 2.24232 1.28843 0 2.33338-1.00416 2.33338-2.24232s-1.04495-2.2423-2.33338-2.2423c-1.28833 0-2.33335 1.00414-2.33335 2.2423z"
            fillRule="evenodd"
          />
          <path d="m12.5819 4.48498c0 .44565-.376.807-.8398.807-.4637 0-.8398-.36135-.8398-.807 0-.44576.3761-.807.8398-.807.4638 0 .8398.36124.8398.807z" />
        </g>
      </svg>
    ),
    href: "https://www.instagram.com/somossofia/",
  },
  {
    icon: (
      <svg
        fill="none"
        height="17"
        viewBox="0 0 16 17"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m9.9535 3.67346h1.3297v-2.226c-.2294-.03033-1.0184-.09858-1.93719-.09858-1.91713 0-3.23042 1.15908-3.23042 3.28941v1.96059h-2.11559v2.4885h2.11559v6.26152h2.59381v-6.26094h2.03l.3223-2.4885h-2.35291v-1.71442c.00061-.71925.20209-1.21158 1.24471-1.21158z"
          fill="#4a25aa"
        />
      </svg>
    ),
    href: "https://www.facebook.com/somossofia/",
  },
  {
    icon: (
      <svg
        fill="none"
        height="17"
        viewBox="0 0 16 17"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="m2.89191 1.32056c-1.04443 0-1.89191.81078-1.89191 1.80997s.84748 1.82693 1.89191 1.82693c1.04442 0 1.8919-.82774 1.8919-1.82693-.00065-.99919-.84814-1.80997-1.8919-1.80997zm12.10429 13.09304v-.0006h.0035v-5.06712c0-2.47886-.5427-4.38838-3.4899-4.38838-1.4168 0-2.36759.7645-2.75575 1.48927h-.04098v-1.25785h-2.79439v9.22408h2.90972v-4.56743c0-1.20259.23184-2.36546 1.7464-2.36546 1.4923 0 1.5146 1.37242 1.5146 2.4426v4.49089zm-10.59137-8.72853h-3.02703v8.72853h3.02703z"
          fill="#4a25aa"
          fillRule="evenodd"
        />
      </svg>
    ),
    href: "https://www.linkedin.com/company/somossofia/mycompany/",
  },
  {
    icon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 835.88 898.04"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m835.88 188.14c-9.85 49.92-19.68 99.75-29.6 150.04-79.6-10.91-150.76-38.39-209.44-97.66v10.36c0 114.49.2 228.98-.06 343.47-.22 94.09-34.98 173.43-107.88 232.98-88.8 72.54-189.96 89.95-296.88 49.1-106.68-40.77-170.61-120.97-188.38-233.99-25.39-161.58 84.6-311.34 245.9-338.66 34.27-5.8 68.79-3.58 103.72-3.99v156.78c-2.37 0-4.7-.02-7.03 0-19.92.21-39.93-.67-59.75.84-74.1 5.65-130.79 68.39-129.84 142.75.94 74.03 58.89 134.8 132.93 139.39 81.62 5.07 150.26-59.31 150.28-141.1.04-196.2 0-392.41 0-588.61v-9.71h7.39c47.39 0 94.78.1 142.17-.13 5.23-.03 7.8 1.69 10.15 6.22 20.2 38.97 44.94 74.69 76.56 105.41 41.06 39.89 89.14 65.76 146.37 73.41 3.73.5 7.41 1.28 11.11 1.96.41.08.79.38 2.28 1.14z"
          fill="#4a25aa"
        />
      </svg>
    ),
    href: "https://www.tiktok.com/@somossofiamx",
  },
  {
    icon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0049 4.62375C14.4841 4.83996 13.9291 4.98327 13.3506 5.05286C13.9458 4.71653 14.4001 4.18803 14.6136 3.551C14.0587 3.86413 13.446 4.08531 12.793 4.20874C12.2661 3.67774 11.515 3.34888 10.6958 3.34888C9.10621 3.34888 7.82651 4.56991 7.82651 6.06679C7.82651 6.28217 7.84577 6.48927 7.89303 6.68642C5.50607 6.57625 3.39396 5.49355 1.97509 3.84425C1.72738 4.25098 1.58208 4.71653 1.58208 5.2177C1.58208 6.15874 2.09413 6.99292 2.8574 7.47587C2.39611 7.46758 1.94358 7.34084 1.5602 7.1412C1.5602 7.14948 1.5602 7.16025 1.5602 7.17102C1.5602 8.49146 2.55542 9.58824 3.8605 9.84089C3.62679 9.90136 3.37208 9.93036 3.10774 9.93036C2.92392 9.93036 2.73836 9.92042 2.56417 9.88397C2.93618 10.96 3.9918 11.7511 5.24698 11.7768C4.27014 12.5 3.02983 12.9357 1.68712 12.9357C1.45166 12.9357 1.22583 12.9258 1 12.8984C2.27182 13.6746 3.7791 14.1178 5.40454 14.1178C10.6879 14.1178 13.5764 9.97592 13.5764 6.38572C13.5764 6.2656 13.572 6.14963 13.5659 6.03449C14.1357 5.65177 14.6145 5.1738 15.0049 4.62375Z"
          fill="#4A25AA"
        />
      </svg>
    ),
    href: "https://twitter.com/SOMOSSOFIA",
  },
];

const LogoContainer = styled("div", {
  gridColumn: "1/-1",
  marginBottom: 36,
  "& svg": {
    width: 74,
  },
  "@bp1": {
    gridColumn: "1/span 2",
  },
});

const LinksContainer = styled("div", {
  gridColumn: "1/-1",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  position: "relative",
  "@bp1": {
    gridColumn: "3/span 10",
    justifyContent: "space-between",
  },
});

const SocialNetworksContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 8,
});

const SocialNetwork = styled("a", {
  width: 16,
  height: 16,
  display: "block",
  "$ svg": {
    width: "100%",
    height: "100%",
  },
});

const Legals = styled("div", {
  gridColumn: "1/-1",
  display: "flex",
  textAlign: "left",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  color: "$blueGray200",
});

export const Footer = () => {
  return (
    <PaddedScreenContainer>
      <Grid css={{ py: "$3", columnGap: 24 }}>
        <LogoContainer>
          <svg width="108" height="37" viewBox="0 0 108 37" fill="none">
            <path
              d="M13.9664 20.5158L11.7086 19.9595C9.3869 19.3414 7.7468 18.9293 7.7468 17.3017C7.7468 15.7977 9.0461 14.9118 10.9418 14.9118C13.2209 14.9118 15.0314 16.0037 15.2444 18.8881H21.1871C20.8676 13.8816 17.417 10.4615 10.8779 10.4615C6.0854 10.4615 1.82539 12.9339 1.82539 17.6108C1.82539 22.2052 5.5103 23.462 8.7479 24.2037L11.027 24.76C13.6469 25.4193 15.287 26.161 15.287 27.9328C15.287 29.6223 13.6469 30.3228 11.7512 30.3228C9.2804 30.3228 7.3421 29.3338 7.0013 26.8203H1.05859C1.39939 31.6414 4.9778 34.773 11.7938 34.773C16.9271 34.773 21.1871 32.6303 21.1871 27.8092C21.1871 23.4208 18.1838 21.5459 13.9664 20.5158Z"
              fill="#4A25AA"
            />
            <path
              d="M78.6121 11.6771H72.4351V34.0931H78.6121V11.6771Z"
              fill="#4A25AA"
            />
            <path
              d="M106.366 29.8695C105.216 29.8695 104.641 29.3132 104.641 28.0977V19.568C104.641 13.3254 100.061 10.5646 94.4168 10.5646C88.2398 10.5646 84.4058 13.9641 83.9159 18.8057H90.0929C90.4763 16.7042 91.8182 15.3032 94.4381 15.3032C96.8024 15.3032 98.4851 16.56 98.4851 19.1148V20.1037L90.9449 20.8866C86.5145 21.2987 83 23.5032 83 27.974C83 32.5891 86.621 34.8348 91.52 34.8348C95.3327 34.8348 97.8248 33.578 99.4649 31.5795C100.274 33.7222 102.063 34.3815 104.321 34.3815H106.92V29.9107H106.366V29.8695ZM98.4638 25.3575C98.4638 29.0454 95.1836 30.261 92.585 30.261C90.668 30.261 89.1557 29.6017 89.1557 27.7474C89.1557 25.9343 90.8384 25.0484 93.0536 24.8218L98.4425 24.2655V25.3575H98.4638Z"
              fill="#4A25AA"
            />
            <path
              d="M39.8678 34.4446C46.6453 32.8966 50.8422 26.3273 49.2418 19.7717C47.6415 13.216 40.8499 9.1565 34.0725 10.7045C27.295 12.2524 23.0981 18.8217 24.6985 25.3774C26.2988 31.933 33.0904 35.9925 39.8678 34.4446Z"
              fill="#4A25AA"
            />
            <path
              d="M76.099 2.24097L73.2661 8.68969H77.4622C77.7817 8.68969 78.0799 8.52486 78.2503 8.25702L82.4251 1.68469H76.9297C76.5889 1.7053 76.2481 1.91132 76.099 2.24097Z"
              fill="#4A25AA"
            />
            <path
              d="M67.6425 1.89075C67.5999 1.87015 67.536 1.87015 67.4934 1.84955C67.4721 1.84955 67.4508 1.84955 67.4295 1.82894C66.471 1.58171 65.3421 1.37568 64.128 1.33447C64.0002 1.33447 63.8724 1.33447 63.7446 1.33447C59.0586 1.33447 55.7784 3.72441 55.7784 8.52489V11.6565H50.7729V16.3128H55.7784V34.1137H61.8915V16.354H68.0898V11.6771H61.8915V8.87514C61.8915 6.93847 63.0843 6.05254 64.8522 6.05254C66.3219 6.05254 67.8342 6.58822 68.601 6.81485L69.6447 2.52944C69.2613 2.40582 68.7714 2.2204 68.2176 2.05557C68.0259 1.99377 67.8342 1.95256 67.6425 1.89075Z"
              fill="#4A25AA"
            />
          </svg>
        </LogoContainer>
        <LinksContainer>
          {LINKS.map((link, index) => (
            <FooterLinkBlock key={`link-block-${index}`} {...link} />
          ))}
          <FooterLinkBlock title={"Síguenos"}>
            <SocialNetworksContainer>
              {SOCIAL_LINKS.map((link, index) => (
                <SocialNetwork
                  key={`social-link-${index}`}
                  href={link.href}
                  target={"_blank"}
                  referrerPolicy={"no-referrer"}
                >
                  {link.icon}
                </SocialNetwork>
              ))}
            </SocialNetworksContainer>
          </FooterLinkBlock>
        </LinksContainer>
        <Legals>CNSF - H0717 CONDUSEF - 22717</Legals>
      </Grid>
    </PaddedScreenContainer>
  );
};
