import { States } from "~/types/graphql";
import { HeaderSelectOption } from "~/components";

export const StatesTranslations = {
  [States.Aguascalientes]: "Aguascalientes",
  [States.BajaCalifornia]: "Baja California",
  [States.BajaCaliforniaSur]: "Baja California Sur",
  [States.Campeche]: "Campeche",
  [States.Chiapas]: "Chiapas",
  [States.Chihuahua]: "Chihuahua",
  [States.CiudadDeMexico]: "Ciudad de México",
  [States.CoahuilaDeZaragoza]: "Coahuila de Zaragoza",
  [States.Colima]: "Colima",
  [States.Durango]: "Durango",
  [States.Guanajuato]: "Guanajuato",
  [States.Guerrero]: "Guerrero",
  [States.Hidalgo]: "Hidalgo",
  [States.Jalisco]: "Jalisco",
  [States.Mexico]: "México",
  [States.MichoacanDeOcampo]: "Michoacán de Ocampo",
  [States.Morelos]: "Morelos",
  [States.Nayarit]: "Nayarit",
  [States.NuevoLeon]: "Nuevo León",
  [States.Oaxaca]: "Oaxaca",
  [States.Puebla]: "Puebla",
  [States.Queretaro]: "Querétaro",
  [States.QuintanaRoo]: "Quintana Roo",
  [States.SanLuisPotosi]: "San Luis Potosí",
  [States.Sinaloa]: "Sinaloa",
  [States.Sonora]: "Sonora",
  [States.Tabasco]: "Tabasco",
  [States.Tamaulipas]: "Tamaulipas",
  [States.Tlaxcala]: "Tlaxcala",
  [States.VeracruzDeIgnacioDeLaLlave]: "Veracruz de Ignacio de la Llave",
  [States.Yucatan]: "Yucatán",
  [States.Zacatecas]: "Zacatecas",
};

export const StatesList = (): HeaderSelectOption[] => {
  const allValues = Object.values(States).map(state => {
    return {
      value: state,
      label: StatesTranslations[state],
    };
  });

  return [
    {
      value: "TODOS",
      label: "Ver en todos los estados",
    },
    ...allValues,
  ];
};
